/**
 * The Root container
 */
import React, { useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router";
import { Route, Switch, Redirect } from "react-router-dom";
import Sticky from "react-sticky-el";
import Loadable from "react-loadable";

import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import TopBar from "../components/TopBar";
import TeaserBar from "../components/TeaserBar";
import OfflineBar from "../components/OfflineBar";
import ScrollToTop from "../components/ScrollToTop";
import LoadingContent from "../components/LoadingContent";
import CookieConsentModal from "../components/CookieConsentModal";
import { isServer } from "../redux/store";
import StaticHeader from "../components/Page/utils/StaticHeader";

// Loadable components
const loadableOptions = (loader, layout = null, invert = false) => ({
  loader,
  loading: () => <LoadingContent layout={layout} invert={invert} />,
  delay: 600,
});

const Home = Loadable(loadableOptions(() => import("./CMS/Home")));
const HomeWithSearch = Loadable(
  loadableOptions(() => import("./CMS/HomeWithSearch"))
);
const TextEditor = Loadable(
  loadableOptions(() => import("./TextEditor/TextEditor"), "editor")
);
const FileUploadEditor = Loadable(
  loadableOptions(() => import("./FileUploadEditor/FileUploadEditor"), "editor")
);
const BlankCutEditor = Loadable(
  loadableOptions(
    () => import("./BlankCutEditor/BlankCutEditor"),
    "editor",
    true
  )
);
const Landingpage = Loadable(
  loadableOptions(() => import("./CMS/Landingpage"))
);
const FourOFour = Loadable(loadableOptions(() => import("./Utils/FourOFour")));
const AccountRoot = Loadable(
  loadableOptions(() => import("./Account/AccountRoot"))
);

// Shop Loadables
const ShopCategory = Loadable(
  loadableOptions(() => import("./Shop/ShopCategoryLp"), "category", true)
);
const ShopCategoryLegacy = Loadable(
  loadableOptions(() => import("./Shop/ShopCategory"), "category", true)
);
const ShopProduct = Loadable(
  loadableOptions(() => import("./Shop/ShopProduct"), "product", true)
);
const ShopSearch = Loadable(
  loadableOptions(() => import("./Shop/ShopSearch"), "category", true)
);
const Shop = Loadable(
  loadableOptions(() => import("./Shop/ShopHome"), null, true)
);
const CheckoutRoot = Loadable(
  loadableOptions(() => import("./Checkout/CheckoutRoot"))
);
const BlogRoot = Loadable(loadableOptions(() => import("./Blog/BlogRoot")));
const SupportCenterRoot = Loadable(
  loadableOptions(() => import("./SupportCenter/SupportCenterRoot"))
);
const DesignReview = Loadable(
  loadableOptions(() => import("./DesignReview/DesignReview"))
);
const DesignReviewDialog = Loadable(
  loadableOptions(() => import("./DesignReview/DesignReviewDialog"))
);

/**
 * The Root container used as entry point for the react-router.
 * All routes are lazy-loaded with Loadable.
 */
const Root = (props) => {
  const [enforcePrivacyConsentModal, setEnforcePrivacyConsentModal] =
    useState(false);

  const validLanguage = ["/de/", "/en/", "/es/", "/it/"].some((lang) =>
    props.location.pathname.toLowerCase().startsWith(lang)
  );

  const focusMode = [
    "/de/checkout/process",
    "/en/checkout/process",
    "/es/checkout/process",
    "/it/checkout/process",
  ].some((path) => props.location.pathname.toLowerCase().startsWith(path));

  const checkPrivacyConsent = ![
    "/de/p/datenschutz/",
    "/en/p/datenschutz/",
    "/es/p/datenschutz/",
    "/it/p/datenschutz/",
    "/de/p/impressum/",
    "/en/p/impressum/",
    "/es/p/impressum/",
    "/it/p/impressum/",
  ].some((path) => props.location.pathname.toLowerCase().startsWith(path));

  return (
    <>
      {!validLanguage && <Redirect to={`https://www.myfolie.com/de/`} />}
      <StaticHeader />
      <OfflineBar />
      {props.lang === "de" && <TeaserBar />}
      <TopBar isCheckout={focusMode} />
      {/* {!focusMode && <Navbar />} */}

      {!focusMode && (
        <Sticky className="sticky--wrapper" topOffset={37}>
          <Navbar />
        </Sticky>
      )}
      <div id="content">
        <ScrollToTop isServer={isServer}>
          <Switch>
            <Redirect exact from="/" to={`/de/`} />
            <Route exact path={"/:lang/"} component={Home} />
            <Route exact path={"/:lang/overview/"} component={HomeWithSearch} />
            <Route exact path={"/:lang/search/"} component={ShopSearch} />
            <Route path={"/:lang/klebeschriften/"} component={TextEditor} />
            <Route
              path={"/:lang/eigenes-motiv/"}
              component={FileUploadEditor}
            />
            <Route
              path={"/:lang/folienzuschnitt/"}
              component={BlankCutEditor}
            />
            <Route path={"/:lang/checkout/"} component={CheckoutRoot} />
            <Route exact path={"/:lang/p/:slug"} component={Landingpage} />
            <Route
              exact
              path={"/:lang/kontakt/"}
              component={SupportCenterRoot}
            />
            <Route path={"/:lang/faq/"} component={SupportCenterRoot} />
            <Route path={"/:lang/faqs/"} component={SupportCenterRoot} />
            <Route path={"/:lang/fileguide/"} component={SupportCenterRoot} />

            <Route
              exact
              path={"/:lang/order_reviews/:slug?"}
              component={DesignReview}
            />
            <Route
              path={"/:lang/order_reviews/:slug/:feedbackId"}
              component={DesignReviewDialog}
            />

            <Route path={"/:lang/blog/"} component={BlogRoot} />
            <Route exact path={"/:lang/shop/"} component={Shop} />
            <Route
              exact
              path={"/:lang/c/:slug?"}
              component={ShopCategoryLegacy}
            />
            <Route exact path={"/:lang/t/:slug?"} component={ShopCategory} />
            <Route
              exact
              path={"/:lang/c/:slug?/:subslug?"}
              component={ShopCategoryLegacy}
            />
            <Route
              exact
              path={"/:lang/t/:slug?/:subslug?"}
              component={ShopCategory}
            />
            <Route
              exact
              path={"/:lang/t/:slug?/:subslug?/:subsubslug?"}
              component={ShopCategory}
            />
            <Route exact path={"/:lang/i/:slug?"} component={ShopProduct} />

            <Route path={"/:lang/account/"} component={AccountRoot} />

            <Route component={FourOFour} />
          </Switch>
        </ScrollToTop>
      </div>
      <Footer
        lang={props.lang}
        isMinimalFooter={focusMode}
        setEnforcePrivacyConsentModal={setEnforcePrivacyConsentModal}
      />
      {(checkPrivacyConsent || enforcePrivacyConsentModal) && (
        <CookieConsentModal
          lang={props.lang}
          setEnforcePrivacyConsentModal={setEnforcePrivacyConsentModal}
          enforcePrivacyConsentModal={enforcePrivacyConsentModal}
        />
      )}
    </>
  );
};

/**
 * Selects props from the store and passes them to the component
 * @param {Object} state the store
 */
const mapStateToProps = (state) => ({
  lang: state.i18nState.lang,
});
/**
 * Binds dispatch method to the actions passed as props to the component
 */
const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

/** export the redux connected component and add a frontload call */
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Root));
